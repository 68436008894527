import { Grid, Typography, Button, TextField, Link } from "@material-ui/core";
import { Send } from "@material-ui/icons";

import React, { useState } from "react";
import { Layout } from "../components/Layout";

import { Navbar } from "../components/Navbar";

const AboutPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSend = () => {
    const encodedMessage = encodeURIComponent(`
      Hi my name is ${name}, my email is ${email} \n
      ${message}
    `);
    window.open("mailto:info@findlocalketo.com?body=" + encodedMessage);
  };

  return (
    <Layout>
      <Navbar title="About Us" subtitle="Let us tell you a little about us!" />
      <Grid container justify="center" style={{ marginTop: "4rem" }}>
        <Grid item xs={12} lg={6}>
          <Typography style={{ marginBottom: "2rem" }}>
            We know how frustrating it can be to start a new lifestyle and not
            know where to go to eat? Who has Keto options? Are there Keto
            bakeries? All great questions with unfortunately, not so great
            answers. Well we wanted to change that! Welcome Local Keto! The app
            where we help you find the best places closest to you with choices
            that fit your Keto lifestyle! With one simple click, the world
            becomes your oyster (low carb, keto friendly oyster of course 😉 )
          </Typography>
          <Typography style={{ marginBottom: "2rem" }}>
            We’re excited for you to enjoy the app and please contact us if you
            have any questions, praises or concerns!
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AboutPage;
